<template>
</template>

<script>
// @ is an alias to /src
import http from '../mixins/api'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Password from 'primevue/password';
import Dialog from 'primevue/dialog';
import store from '@/store'
import { v4 } from "uuid"


export default {
    name: 'AdminStart',
    components: {
    },
    data: function() {
        return {
        }
    },

    async beforeRouteEnter(route, redirect, next) {
        store.commit("auth/logout")
        store.dispatch("auth/logout")
        await store.dispatch('auth/authPoint', {create_account: true})
    },

    methods: {
    }
}
</script>

<style scoped>
</style>
